<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="EditLine()">
      <div class="mb-3">
                <div class="row">

                    <div class="col-sm form-group">
                        <label>types </label>

                        <CustomSelect
                            :key="form.typesmoyenstransport"
                            :columnDefs="['id','libelle']"
                            :oldValue="form.typesmoyenstransports"
                            :renderCallBack="(data)=>`${data.libelle}`"
                            :selectCallBack="(data)=>{form.typesmoyenstransport_id=data.id;form.typesmoyenstransport=data}"
                            :url="`${axios.defaults.baseURL}/api/typesmoyenstransports-Aggrid1`"
                            filter-key=""
                            filter-value=""
                        />
                        <div v-if="errors.typesmoyenstransport_id" class="invalid-feedback">
                            <template v-for=" error in errors.typesmoyenstransport_id"> {{ error[0] }}</template>

                        </div>
                    </div>
                    <div class="col-sm form-group">
                        <label>code </label>
                        <input v-model="form.moyenstransports.code" :class="errors.code?'form-control is-invalid':'form-control'"
                               type="text">

                        <div v-if="errors.code" class="invalid-feedback">
                            <template v-for=" error in errors.code"> {{ error[0] }}</template>

                        </div>
                    </div>
                    <div class="col-sm form-group">
                        <label>libelle </label>
                        <input v-model="form.moyenstransports.libelle" :class="errors.libelle?'form-control is-invalid':'form-control'"
                               type="text">

                        <div v-if="errors.libelle" class="invalid-feedback">
                            <template v-for=" error in errors.libelle"> {{ error[0] }}</template>

                        </div>
                    </div>
                </div>


            </div>
            <div class="row">
                <div class="col-sm">
                    <h2>Programmes</h2>

                    <LignesmoyenstransportsView :parentId="form.id"/>
                </div>
            </div>
      <div class="d-flex justify-content-between">
        <button class="btn btn-primary" type="submit">
          <i class="fas fa-floppy-disk"></i> Mettre à jour
        </button>
        <button class="btn btn-danger" type="button" @click.prevent="DeleteLine()">
          <i class="fas fa-close"></i> Supprimer
        </button>
      </div>
    </form>
  </b-overlay>
</template>

<script>
import LignesmoyenstransportsView from "../Lignesmoyenstransports/LignesmoyenstransportsView.vue"

export default {
  name: 'EditMoyenstransports',
  components: {CustomSelect: () => import("@/components/CustomSelect.vue"),    Files: () => import("@/components/Files.vue"),    LignesmoyenstransportsView: () => import("../Lignesmoyenstransports/LignesmoyenstransportsView.vue"),},
  props: ['data', 'gridApi', 'modalFormId',
    'typesmoyenstransportsData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        code: "",

        libelle: "",

        typesmoyenstransport_id: "",

        creat_by: "",

        extra_attributes: "",

        created_at: "",

        updated_at: "",

        deleted_at: "",
      }
    }
  },

  mounted() {
    this.form = this.data
  },
  methods: {

    EditLine() {
      this.isLoading = true
      this.form.code=this.form.moyenstransports.code
      this.form.libelle=this.form.moyenstransports.libelle
      this.axios.post('/api/moyenstransports/' + this.form.id + '/update', this.form).then(response => {
        this.isLoading = false
        this.gridApi.applyServerSideTransaction({
          update: [
            response.data
          ],
        });
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    DeleteLine() {
      this.isLoading = true
      this.axios.post('/api/moyenstransports/' + this.form.id + '/delete').then(response => {
        this.isLoading = false

        this.gridApi.applyServerSideTransaction({
          remove: [
            this.form
          ]
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        console.log(error.response.data)
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de la suppression')
      })
    },
  }
}
</script>
